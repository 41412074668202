.homepage {
    margin-bottom: 5rem;
    .search {
        .search-input-fields-disclamer {
            width: 57rem;
            margin: 1rem auto 0;
            opacity: 0.5;
            text-align: center;
        }

        .search-input-fields {
            @include flex(row, center, center);
        }

        .search-input-fields-disclamer {
            @include flex(column, center, center);
        }
    }
    .search-results {
        @include flex(column, flex-start, center);
        background: white;
        border-radius: 3px;
        box-shadow: 0 0 5px #e2e2e2;
        margin: 1rem auto;
        max-width: 64rem;
        padding: 1rem;
        padding: 4rem;
        width: 84vw;

        .search-description {
            width: 100%;

            .ant-descriptions-view {
                opacity: 1;
            }
        }

        > div {
            width: 100%;
            margin-bottom: 2rem;

            h2 {
                text-align: center;
                text-transform: uppercase;
                font-size: 2rem;
                font-weight: 400;
                text-shadow: 0 0 5px #a390dc;
                margin-bottom: 0.5rem;
                //padding-bottom: 1rem;
                // background: #333;
                // display: inline;
                // color: #fff;
                // padding: 0.5rem 0.75rem;
                // border-radius: 3px;
                // box-shadow: 0 0 2px #000;
            }
            h4 {
                text-align: center;
                text-transform: uppercase;
                font-size: 0.7rem;
                font-weight: 100;
                padding-bottom: 1rem;
                opacity: 0.5;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.description-tearm {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
    box-shadow: none;
    color: #000;
    transition: all 0.4s ease;

    &:hover {
        color: #8e8eff;
        //text-shadow: 0 0 1px #8e8eff;
    }
}

.cityCountry {
    text-transform: capitalize;
    display: inline-block;
    font-size: 1rem;
    font-weight: 100;
}
span.cityCountry::first-letter {
    text-transform: uppercase !important;
}
