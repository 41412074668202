.loading-spinner {
    // animation-fill-mode: forwards;
    // animation: animacija 0.75s ease 0.51s;
    color: #ccc;
    font-size: 7rem;
    position: fixed;
    top: 50%;
    left: 50%;
    opacity: 1;
    text-align: center;
    transform: translate(-50%, -50%);

    p {
        font-size: 1.5rem;
    }
}

@keyframes animacija {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
