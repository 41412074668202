$register-border-radius: 0.25rem;

.register {
    width: 80vw;
    max-width: 40rem;
    border: 0.1rem solid transparent;
    border-radius: $register-border-radius;
    background-color: #f1f1f1;
    box-shadow: 0 0 0.5rem #ccc;

    form {
        padding: 2rem;
    }

    input {
        border: none;
    }

    .affiliated {
        transition: all 0.5s ease;
        overflow: hidden;
        height: 0;
    }
    .is-affiliated {
        height: 7rem;
    }
    .entity-added {
        height: 0;
    }
}
