@use './variables.scss' as var;

.footer {
    @include flex(row, space-between, center);

    background-color: var.$navbar-footer-background;
    bottom: 0;
    box-shadow: 0 0 0.25rem var.$navbar-footer-background;
    color: white;
    font-size: 0.7rem;
    font-weight: 100;
    height: 5rem;
    left: 0;
    padding: 0 2rem;
    position: fixed;
    text-shadow: 0 0 5px #a390dc;
    width: 100vw;

    .left {
        @include flex(row, space-between, center);
        width: 30rem;

        .copy {
            font-size: 0.8rem;
        }

        .address {
            @include flex(row, flex-start, center);
            font-size: 0.5rem;

            address {
                margin: 0 1rem;
                a {
                    color: #73bcff;
                }
                p {
                    margin: 0;
                }
            }
        }

        .line {
            height: 2rem;
            border-left: 1px solid #eee;
            margin-left: 1rem;
            opacity: 0.5;
        }
    }

    .center {
        img {
            height: 4rem;
        }
    }

    .right {
        width: 30rem;
        font-size: 0.5rem;
        text-align: right;
    }
}
