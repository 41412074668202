.button-form {
   width: 100%;
   padding: 0.6rem 1rem;
   font-weight: 700;
   font-size: 0.75rem;
   background-color: #5a6268;
   border: none;
   border-radius: 0.2rem;
   color: #fff;
   box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.5);
   transition: all 0.25s ease;

   &.button-dark {
      background-color: #333;
      padding: 0.55rem 1rem;
   }

   &:not(:disabled):hover {
      background-color: #444;
   }

   &:disabled {
      opacity: 0.5;
   }

   &:focus {
      outline: none;
   }
}
