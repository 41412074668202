@use './variables.scss' as var;

.exhibition-add-page-container {
    margin-top: 2rem;
    margin-bottom: var.$page-margin-bottom;

    //wait - description
    .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-description {
        @include text3tockice(14rem);
    }

    .exhibition-add-page-content {
        @include flex(column, center, center);

        .exhibition-add-page-steps {
            width: 40rem;
            margin-bottom: 3rem;
            background: #f1f1f1;
            box-shadow: 0 0 0.5rem #ccc;
            padding: 1rem;
            border-radius: 0.3rem;

            .ant-steps-item {
                width: 45%;
            }
        }

        h1 {
            font-weight: 100;
            text-align: center;
            font-size: 3rem;
            margin-bottom: 3rem;
            span {
                font-size: 2rem;
                color: #999;
                display: block;
            }
        }
    }
}
