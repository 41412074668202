.afilijacija {
    .afilijacija-legal-entity {
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-top: 0.5rem;
        transition: all 0.5s ease;
    }
    .show-legal-entity {
        opacity: 1;
        height: 4rem;
        visibility: visible;
    }
}
