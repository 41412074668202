.add-entity {
   width: 100vw;
   height: 100vh;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 10;
   @include flex(row, center, center);
   background-color: rgba(0, 0, 0, 0.75);

   .add-entity-container {
      padding: 2rem;
      padding-top: 0;
      position: relative;
      min-width: 60rem;
      min-height: 25rem;
      background-color: #fff;
      border-radius: 0.5rem;
      box-shadow: 0 0 0.25rem #fff;

      .add-entity-content {
         padding: 2rem;
         border-radius: 0.5rem;
         background-color: #f1f1f1;

         // input {
         //     border: none;
         //     border: 0.1rem solid #ccc;
         //     padding: 1rem;
         // }

         .address {
            transition: all 0.5s ease;
            overflow: hidden;
            height: 0;
         }

         .showAddress {
            height: 32rem;
         }

         .add-city-container {
            transition: all 0.5s ease;
            overflow: hidden;
            height: 0;
         }

         .addCityYourself {
            height: 5.5rem;
         }

         .add-city-select-container {
            transition: all 0.5s ease;
            overflow: hidden;
            height: 4.5rem;
         }

         .hideAddCitySelect {
            height: 0;
         }
      }

      .exit {
         position: absolute;
         top: 1rem;
         right: 2rem;
         font-size: 1.5rem;
         color: #a390dc;
         cursor: pointer;
      }
   }
}
