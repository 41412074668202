@use './variables.scss' as var;

.table-created-author-list {
    max-width: 90rem;
    margin: auto;

    & > div {
        padding: 2rem;
        background-color: #fff;
        box-shadow: var.$tbl-container-shadow;
        border-radius: var.$tbl-container-border-radius;

        & > h2 {
            font-size: 1.6rem;
            border-bottom: 0.1rem solid #eee;
            padding-bottom: 0.5rem;
            margin-bottom: 2rem;
        }
    }

    .no-author-data {
        text-align: center;
    }
    .table {
        font-size: 0.8rem;
        font-weight: 500;
        background-color: #fff;

        thead {
            tr,
            th {
                border-top: none;
                border-bottom: 3px solid #000;
            }
        }
        tbody {
            tr,
            td {
                .btn {
                    padding: 0.2rem 0.5rem;
                    border: none;
                    outline: none !important;
                    font-weight: 700;
                    font-size: 0.8rem;
                    &:hover {
                        text-decoration: none;
                    }
                }

                // #delete {
                //     color: red;
                // }

                //makni sjenu nad kliknutim gumbom
                .btn-link:focus,
                .btn-link.focus {
                    text-decoration: none;
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }
}
