.notification-popup {
   box-shadow: 0 0 5px #ccc;
   border-radius: 5px;
}

.icon-header {
   font-size: 3.5rem;
   color: #a390dc;
}

// @Makni vizualne artefekte sa ant autocomplete polja
div.ant-select-selector {
   border: none !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
   border-color: #d9d9d9 !important;
   box-shadow: none !important;
}

//@at-rootAUTOCOMPLETE background opcije preko koje se prolazi
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
   background: #f5f5f5;
   // color: white;
}

//ikone u koracima [Step], mora biti 0 kako bi bile tocno u sredini
.anticon {
   vertical-align: 1px;
}

// .ant-steps-item-icon {
//     line-height: 25px;
// }

//      STEPS

// širina opisa koraka
.ant-steps-horizontal:not(.ant-steps-label-vertical)
   .ant-steps-item-description {
   max-width: 25rem;
   white-space: normal;
}

// boja označenog koraka
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
   //background: #1890ff;
   background: orange;
   border: 1px solid orange;
}

// active - title
.ant-steps-item-process
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-title {
   color: #222;
   font-weight: 700;
}

// active - description
.ant-steps-item-process
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-description {
   color: #777;
   @include text3tockice(12.5rem);
}

// error - title
.ant-steps-item-error
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-title {
   font-weight: 700;
   color: #f00;
}

// error - description
.ant-steps-item-error
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-description {
   color: #f00;
   @include text3tockice(12.5rem);
}

// wait - title
.ant-steps-item-wait
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-title {
   font-weight: 700;
   color: #777;
}

// wait - description
.ant-steps-item-wait
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-description {
   color: #777;
   @include text3tockice(12.5rem);
}

// finish - icon
.ant-steps-item-finish .ant-steps-item-icon {
   background: #026973;
   border: 1px solid #046a76;

   & > .ant-steps-icon {
      color: #fff;
   }
}
