@mixin register-page-portrait {
   @media (orientation: portrait) {
      @include flex(column, center, center);
   }
}
@mixin register-page-content-portrait {
   @media (orientation: portrait) {
      @include flex(column, center, center);
      h1 {
         font-size: 2rem;
         span {
            font-size: 1rem;
         }
      }
   }
}

.register-page {
   min-height: $content-min-height;
   @include flex(row, center, center);
   .register-page-content {
      height: 100%;
      @include flex(row, center, center);

      h1 {
         font-size: 5rem;
         margin-right: 5rem;
         font-weight: 100;
         span {
            display: block;
            font-size: 3rem;
            color: #999;
         }
      }
      //@include register-page-content-portrait();
   }
}
