.add-new-legal-entity-content {
   border-radius: 0.5rem;
   background-color: #f1f1f1;

   .address,
   .city {
      transition: all 0.5s ease;
      overflow: hidden;
      height: 0;
   }

   .showCity {
      height: 5rem;
   }
   .showAddress {
      height: 33rem;
   }

   .add-city-container {
      transition: all 0.5s ease;
      overflow: hidden;
      height: 0;
   }

   .addCityYourself {
      height: 5.5rem;
   }

   .add-city-select-container {
      transition: all 0.5s ease;
      overflow: hidden;
      height: 4.5rem;
   }

   .hideAddCitySelect {
      height: 0;
   }
}
