.search {
   // position: fixed;
   // top: 0;
   // left: 0;
   // @include flex(row, center, center);
   // width: 100vw;
   // height: 100vh;

   //transform: translate(50%, 50%);
}

.search-no-result {
   transform: translate(-50%, -50%);
}

.search-text,
.search-ddl {
   width: 35rem;

   @media only screen and (max-width: 1194px) {
      width: 30rem;
   }

   border-radius: 5px;
   padding: 0.5rem 1rem;
   font-size: 2rem;
   border: 1px solid #ccc;
   margin: 0 0.2rem;
   transition: box-shadow 0.5s ease;
   color: #666;
}

.search-text:focus,
.search-ddl:focus {
   outline: 0;
   box-shadow: 0 0 10px #a390dc;
}

.search-ddl {
   width: 18rem;
   padding: 0.75rem 1rem;

   @media only screen and (max-width: 1194px) {
      width: 12rem;
   }
}

p.no-results {
   text-align: center;
   margin-top: 2rem;
   font-size: 2rem;
   color: #999;
   opacity: 0.5;
   span.searchTerm {
      font-weight: 700;
      color: #000;
   }
}

::placeholder {
   /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #dedede;
   opacity: 1; /* Firefox */
   font-weight: 100;
}

:-ms-input-placeholder {
   /* Internet Explorer 10-11 */
   color: #dedede;
}

::-ms-input-placeholder {
   /* Microsoft Edge */
   color: #dedede;
}
