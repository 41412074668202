.popup-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    @include flex(row, center, center);
    background-color: rgba(0, 0, 0, 0.75);

    .popup-container-background {
        padding: 2rem;
        padding-top: 0;
        position: relative;
        min-width: 60rem;
        min-height: 16rem;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0 0 0.25rem #fff;

        .popup-container-content {
            padding: 2rem;
            border-radius: 0.5rem;
            background-color: #f1f1f1;
        }

        .exit {
            position: absolute;
            top: 1rem;
            right: 2rem;
            font-size: 1.5rem;
            color: #a390dc;
            cursor: pointer;
        }
    }
}
