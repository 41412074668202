$artwork-button-dimension: 15em;

.artwork-submenu-button {
    margin: 0 3rem;
    .submenu-button-container {
        transition: all 0.15s ease-out;
        width: $artwork-button-dimension;
        height: $artwork-button-dimension;
        border-radius: 100%;

        @include flex(column, center, center);
        color: #ccc;
        &:hover {
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
            transform: scale(0.9);
        }

        .submenu-icon {
            font-size: 3rem;
            color: #222;
        }

        .submenu-text {
            width: inherit;
            text-align: center;
            overflow-wrap: break-word;
            transition: all 0.25s ease-out;
            font-size: 1.1rem;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}
