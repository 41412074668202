.pagination-sm .page-item:first-child .page-link {
    border-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
    border-radius: 0;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.pagination-sm .page-link {
    padding: 0.05rem 0.25rem;
    font-size: 0.7rem;
    line-height: 1.5;
}
