body {
    margin: 0px;
}

#map {
    width: 100%;
    height: 100%;
    z-index: 100;
}

#mapSearchContainer {
    position: fixed;
    top: 20px;
    right: 40px;
    height: 30px;
    width: 180px;
    z-index: 110;
    font-size: 10pt;
    color: #5d5d5d;
    border: solid 1px #bbb;
    background-color: #f8f8f8;
}

.pointer {
    position: absolute;
    top: 86px;
    left: 60px;
    z-index: 99999;
}

.geocoder-control-input {
    background-image: url('https://raw.githubusercontent.com/Esri/esri-leaflet-geocoder/master/src/img/search.png');
}
