$background-color: #111;
$shadow: 0 0 0.25rem $background-color;

.navbar {
   width: 100vw;
   position: fixed;
   z-index: 10;
   top: 0;
   left: 0;
   height: 5rem;
   padding: 0 1vw;
   margin-bottom: 1rem;
   background-color: $background-color;
   @include flex(row, space-between, center);
   box-shadow: $shadow;

   .logo,
   .user {
      width: 15rem;
   }

   .logo {
      img {
         height: 3rem;
      }
      a {
         font-size: 1.2rem;
         color: white;

         &:hover {
            color: orange;
         }
      }
   }

   .user {
      color: white;
      font-weight: 700;
      text-align: right;
      font-size: 0.8rem;

      div {
         @include flex(row, flex-end, center);
      }

      span {
         display: inline-block;
         margin-right: 1rem;
         user-select: none;
         color: orange;
         a {
            text-decoration: none;
         }
      }
   }

   nav {
      ul {
         @include flex(row, center, center);
         margin: 0;
         li {
            position: relative;
            list-style: none;
            a {
               color: #fff;
               font-size: 1.2rem;
               font-weight: 700;
               margin: 0 1.5rem;
               text-decoration: none;
               text-transform: uppercase;
            }
         }
      }
   }
}
