.author-details {
   width: 80vw;
   max-width: 80rem;
   margin: 4rem auto;
   .basic-information {
      @include flex(row, flex-start, center);
      .txt {
         @include flex(column, flex-start, flex-start);

         .name {
            h1 {
               margin: 0;
               font-weight: 700;
               font-size: 3rem;
            }
         }
         .address {
            a {
               display: inline-block;
               margin-right: 1rem;
            }
         }
      }

      .pic {
         display: inline-block;
         // cursor: pointer;
         margin-right: 1rem;
      }
   }

   .details {
      margin-top: 2rem;
      margin-bottom: 3rem;
      background: #fefefe;
      padding: 3rem;
      border-radius: 5px;
      box-shadow: 0 0 5px #ccc;

      .author-photographs {
         @include flex(row, center, center);
      }

      .info {
         margin-bottom: 3rem;
         h2 {
            text-align: left;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 400;
            background: #333;
            display: inline-block;
            color: #fff;
            padding: 0.25rem 0.75rem;
            border-radius: 5px;
            margin-bottom: 1rem;
         }

         h2 + table.table {
            td:first-of-type {
               width: 20rem;
               font-weight: 700;
            }
         }

         #bibliography {
            width: 100%;
            padding: 0;
            margin: 0;
            tr,
            td {
               padding: 0;
               margin: 0;
               //background: transparent;
            }
            td:first-of-type {
               width: 100px;
               font-weight: 700;
            }
         }

         .artworks {
            .artwork-container {
               margin: 0 0 2rem;
               padding: 1rem;
               box-shadow: 0 0 5px #ccc;

               .artwork-photographs {
                  .image-container {
                     cursor: pointer;
                     display: inline-block;
                     margin: 0.5rem;
                  }
               }
               table {
                  td:first-of-type {
                     font-weight: 700;
                     width: 100px;
                  }
               }
               p {
                  font-size: 0.7rem;
               }
            }
         }

         .exhibitions {
            table {
               width: 100%;
               thead {
                  tr,
                  th {
                     border-top: none;
                     border-bottom: 2px solid #000;
                  }
               }
               td:not(:last-of-type) {
                  width: 45%;
               }
               td {
                  .btn {
                     margin: 0;
                     padding: 0;
                     &:hover {
                        text-decoration: none;
                     }
                  }
               }
            }
         }
         .btn-link {
            outline: none;
            box-shadow: none;
         }
         .btn-link:hover {
            text-decoration: none;
         }
      }
   }
}
