.exhibition-details {
   width: 80vw;
   max-width: 80rem;
   margin: 4rem auto;
   .basic-information {
      @include flex(row, flex-start, center);
      .txt {
         @include flex(column, flex-start, flex-start);

         .name {
            h1 {
               margin: 0;
               font-weight: 700;
               font-size: 3rem;
            }
         }
         .address {
            a {
               display: inline-block;
               margin-right: 1rem;
            }
         }
      }

      .pic {
         display: inline-block;
         // cursor: pointer;
         margin-right: 1rem;
      }
   }

   .details {
      margin-top: 2rem;
      margin-bottom: 3rem;
      background: #fefefe;
      padding: 3rem;
      border-radius: 5px;
      box-shadow: 0 0 5px #ccc;

      .exhibition-photographs {
         @include flex(row, center, center);
         .image-container {
            @include flex(column, center, center);
            div.ant-image {
               margin-top: 0.75rem;
            }
            a {
               display: inline-block;
               width: 200px;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
               text-align: center;
            }
         }

         .artwork-name {
            width: 200px;
            height: 135px;
            border: 4px solid black;
            @include flex(row, center, center);
            background: linear-gradient(#555, #000, #555);
            color: white;
            box-shadow: 0 0 4px black;
         }
      }

      .info {
         margin-bottom: 3rem;
         h2 {
            text-align: left;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 400;
            background: #333;
            display: inline-block;
            color: #fff;
            padding: 0.25rem 0.75rem;
            border-radius: 5px;
            margin-bottom: 1rem;
         }

         table.table {
            td:first-of-type {
               width: 300px;
               font-weight: 700;
            }
         }

         table.parent-table {
            td:first-of-type {
               width: 200px;
               font-weight: 700;
            }
         }

         table.sub-table {
            width: 100%;
            padding: 0;
            margin: 0;
            tr,
            td {
               padding: 0;
               margin: 0;
               //background: transparent;
            }
            td:first-of-type {
               width: 120px;
               font-weight: 700;
            }
         }
      }
   }
}
