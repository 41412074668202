.submenu {
    background-color: #f1f1f1;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem #ccc;
    height: 4rem;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 21rem;
    z-index: 5;
    transition: all 0.25s ease-out;

    .submenu-text {
        bottom: 0;
        //color: #a390dc;
        color: #333;
        font-weight: 700;
        left: 0;
        padding-bottom: 0.25rem;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        width: inherit;
    }
}

.show-submenu {
    top: 3rem;
}
