.button-save {
   border-radius: 0.15rem;
   border: none;
   color: #fff;
   font-size: 1.4rem;
   font-weight: 700;
   padding: 1rem 2rem;
   width: 100%;
   transition: all 0.25s ease;

   &:hover {
      letter-spacing: 0.1rem;
      box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.75);
      text-shadow: 0 0 0.25rem rgba(255, 255, 255, 1);
   }

   &.primary-mode {
      background-color: #044247;
   }
   &.edit-mode {
      background-color: #61815c;
   }
}
