.pick-or-custom-enter {
    .custom-enter-container {
        transition: all 0.5s ease;
        overflow: hidden;
        height: 0;
    }
    .customEnter {
        height: 5.5rem;
    }

    .pick-or-custom-container {
        transition: all 0.5s ease;
        overflow: hidden;
        height: 4.5rem;
    }

    .hidePick {
        height: 0;
    }
}
