.admin-list-confirmed-page {
   margin-top: 2rem;
   @include flex(row, center, flex-start);
   width: 100%;

   .admin-list-confirmed-page-content {
      width: 100%;

      h1 {
         font-size: 3rem;
         font-weight: 100;
         margin-bottom: 5rem;
      }
   }
}
