$content-min-height: calc(100vh - 12rem);

* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}
html {
   @media only screen and (max-width: 1194px) {
      font-size: 70%;
   }
   font-size: 100%;
   min-height: 100vh;
   color: #222;
   scrollbar-width: thin;
   scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

body {
   font-family: 'Quicksand', sans-serif !important;
   font-weight: 500 !important;
   background-color: #fcfcfc;
   min-height: inherit;
   overflow-x: hidden;

   #root {
      min-height: inherit;
   }
}

.homepage-title {
   color: white;
   margin: 0;
   font-weight: 100;
   font-size: 1.8rem;
   text-shadow: 0 0 5px #a390dc;
}

*::-webkit-scrollbar {
   width: 5px;
   height: 100%;
}

*::-webkit-scrollbar-track {
   background: transparent;
}

*::-webkit-scrollbar-thumb {
   background-color: rgb(50, 50, 50);
   border-radius: 20px;
   border: transparent;
}

.btn,
.info h2 {
   box-shadow: 0 0 2px #000;
}

td .btn {
   box-shadow: none;
}

@mixin flex($direction, $justyfyContent, $alignItems) {
   display: flex;
   flex-direction: $direction;
   justify-content: $justyfyContent;
   align-items: $alignItems;
   flex-wrap: wrap;
}

.form-group {
   margin-bottom: 0.75rem;
}

h3 {
   font-size: 1.5rem;
}

.alert {
   position: relative;
   padding: 0.25rem 1rem;
   margin-bottom: 1rem;
   border: 1px solid transparent;
   border-radius: 0.25rem;
   font-size: 0.9em;
}

.form-style {
   background-color: #f1f1f1;
   border-radius: 0.25rem;
   border: 0.1rem solid transparent;
   box-shadow: 0 0 0.5rem #ccc;
   max-width: 40rem;
   padding: 1.5rem;
   width: 80vw;
}

.form-style-width {
   background-color: #f1f1f1;
   border-radius: 0.25rem;
   border: 0.1rem solid transparent;
   box-shadow: 0 0 0.5rem #ccc;
   max-width: 80rem;
   padding: 1.5rem;
   width: 80vw;
}

.form-style,
.form-style-width {
   margin-bottom: 2rem;
   //naslov na formama
   h2 {
      border-bottom: 1px solid #ddd;
      color: #222;
      font-size: 1.5rem;
      font-weight: 900;
      margin: 0 0 1rem;
      padding-bottom: 0.3rem;
      text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.5);
   }

   input,
   select,
   textarea,
   .ant-select,
   .ant-select-dropdown {
      font-weight: 500;
      color: #666;
   }
}

h5 {
   font-size: 2rem;
   padding-bottom: 1.5rem;
   text-align: left;
   color: #a390dc;
   font-weight: 900;
}

.list-container {
   margin-top: 0.2rem;
   .table {
      margin: 0 0 0.5rem;
      font-size: 0.7rem;
      width: 100%;
      font-weight: 700;
      box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);

      tr,
      td,
      th {
         padding: 0.1rem 0.3rem;
      }
      .btn {
         padding: 0.1rem 0.4rem;
         font-size: 0.7rem;
         font-weight: 700;
      }
   }
}

.label-style {
   margin-bottom: 2px;
   font-weight: 700;
   font-size: 0.9rem;
}

@mixin text3tockice($width) {
   width: $width;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

// ArtworkTechnique, AuthorAreaOfInterests
.selected-options-grid {
   display: grid;
   grid-template-rows: 1.5rem;
   grid-template-columns: repeat(5, 20%);
   gap: 0.2rem;
}

.selected-option-style {
   background: #7d746c;
   color: #fff;
   border-radius: 0.2rem;
   cursor: pointer;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   padding: 0 0.5rem;
   line-height: 1.5rem;
   text-align: center;
}

/*  THUMBNAILS  */

.image-container {
   width: 200px;
   cursor: pointer;
   margin: 1rem;

   div.ant-image {
      overflow: hidden;
      border: 4px solid black;
      box-shadow: 0 0 4px black;

      img {
         object-fit: cover;
         transition: all 0.2s ease;

         &:hover {
            transform: scale(1.1);
         }
      }
   }
}

div.rest-pictures {
   @include flex(row, center, center);

   div.ant-image {
      overflow: hidden;
      border: 4px solid black;
      box-shadow: 0 0 4px black;

      img {
         object-fit: cover;
         transition: all 0.2s ease;

         &:hover {
            transform: scale(1.1);
         }
      }
   }
}

.btn-primary,
.btn-primary:hover {
   background-color: #026973;
   border-color: #046a76;
}

.pagination {
   .active {
      .page-link {
         background-color: #333 !important;
      }
   }
}
tbody {
   td {
      button {
         min-width: 5rem !important;
      }
   }
}
