.user-confirmation-edit-content {
   border-radius: 0.5rem;
   background-color: #f1f1f1;

   // input {
   //     border: none;
   //     border: 0.1rem solid #ccc;
   //     padding: 1rem;
   // }

   .address {
      transition: all 0.5s ease;
      overflow: hidden;
      height: 0;
   }

   .showAddress {
      height: 32rem;
   }

   .add-city-container {
      transition: all 0.5s ease;
      overflow: hidden;
      height: 0;
   }

   .addCityYourself {
      height: 5.5rem;
   }

   .add-city-select-container {
      transition: all 0.5s ease;
      overflow: hidden;
      height: 4.5rem;
   }

   .hideAddCitySelect {
      height: 0;
   }
}
