$register-border-radius: 0.25rem;

.login-user {
   width: 80vw;
   max-width: 40rem;
   border: 0.1rem solid transparent;
   border-radius: $register-border-radius;
   background-color: #f1f1f1;
   box-shadow: 0 0 0.5rem #ccc;

   form {
      padding: 2rem;

      & > div {
         font-size: 0.8rem;
      }
   }

   header {
      background-color: #545b62;
      background: linear-gradient(90deg, #222, #555);
      border-radius: 0.25rem;
      padding: 0.75rem;
      color: #fff;
      @include flex(row, flex-start, center);

      h3 {
         font-weight: 300;
         margin: 0;
         font-size: 1.25rem;
      }
   }

   input {
      border: none;
   }
}
