.app {
    min-height: inherit;
    .content {
        padding: 7rem 2rem 3rem;
        min-height: $content-min-height;
    }
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 50% 50%;
}

.myToast {
    font-size: 0.75rem;
    font-weight: 400;
}
