.atelier {
    .atelier-description {
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-top: 0.5rem;
        transition: all 0.5s ease;
    }
    .show-atelier-description {
        opacity: 1;
        height: 5rem;
        visibility: visible;
    }
}
