.not-found-page {
    min-height: $content-min-height;
    @include flex(row, center, center);
    .not-found-content {
        height: 100%;
        @include flex(row, center, center);

        h1 {
            font-size: 5rem;
            margin-right: 5rem;
            font-weight: 100;
            span {
                display: block;
                font-size: 3rem;
                color: #999;
            }
        }
    }
}
